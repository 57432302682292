import React, { Component } from 'react';

class Privado extends Component {
    render() {
        return (
            <div className="container">
                <h1>VendeMas</h1>
                <h2>Admin</h2>
            </div>
        );
    }
}

export default Privado;
