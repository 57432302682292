import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

function formatoNivel(cell, row) {
    return (<div>
        {(row.es_admin) && (<div>Administrador</div>)}
        {(row.es_bodeguero) && (<div>Bodeguero</div>)}
        {(row.es_cajero) && (<div>Cajero</div>)}
    </div>);
}

export class UsuariosListado extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        idEmpresa: PropTypes.number.isRequired,
    }

    static defaultProps = {
        idEmpresa: 0
    }

    constructor(props) {
        super(props)
    }

    render() {
        // state
        const { data, idEmpresa } = this.props;
        
        // bind
        const { eliminar } = this.props;

        const options = _.cloneDeep(tableOptions);
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <Link className="btn btn-primary" to={`/empresas/${idEmpresa}/usuarios/crear`}>+ Agregar Usuario</Link>
                    <div className="grid-container">
                        <LoadMask loading={false} dark blur>
                            <BootstrapTable
                                data={false ? [] : data}
                                remote hover
                                fetchInfo={{ dataTotalSize: data.length }} options={options}>
                                <TableHeaderColumn dataField='first_name' >Nombre</TableHeaderColumn>
                                <TableHeaderColumn dataField='last_name' >Apellido</TableHeaderColumn>
                                <TableHeaderColumn dataField='usuario' >Usuario</TableHeaderColumn>
                                <TableHeaderColumn dataField='id' dataFormat={formatoNivel} >Rol</TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField='id' isKey dataAlign="center" width="20%"
                                    dataFormat={
                                        (cell, row) => {
                                            if (row.sucursal === null)
                                                return "";
                                            return activeFormatter({editar: `/empresas/${idEmpresa}/usuarios/editar`, eliminar })(cell, row)
                                        }
                                    }
                                >Acciones</TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>    
                </div>
            </div>
        )
    }
}
