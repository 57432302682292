import React, { Component } from 'react';
import  PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { activeFormatter } from "../../Utils/Acciones/Acciones"
import EmpresaForm from './EmpresaCrearForm'

class EmpresaCrear extends Component {
    static propTypes = {
    }

    constructor(props) {
        super(props)
        this.state = {
            certificado:null,
            llave:null
        }
    }

    componentDidMount() {
        this.props.asignarCategoria([]);
        this.props.setCategoriasExpanded([]);
        this.props.initCreacionEmpresa();
    }

    handleselectedFileCertificado = event => {
        this.setState({
            certificado: event.target.files[0]
        })
    }
    handleselectedFileLlave = event => {
        this.setState({
            llave: event.target.files[0]
        })
    }

    guardarEmpresa = () => {
        const archivos = [
            {name: "certificado", file: this.state.certificado},
            {name: "llave", file: this.state.llave}
        ]

        this.props.submit(archivos);
    }

    render(){
        // state
        const { formato_factura, formato_factura_fel, datos_ejemplo, categorias, categorias_seleccionados, categorias_expanded, print_state } = this.props;
        const { llave, certificado } = this.state;
        // bind
        const { cambioFormatoFactura, asignarCategoria, setCategoriasExpanded, cambioTipoFormatoFactura, print } = this.props;

        return (
            <div className="row mt-4">
                <div className="card py-4 col-lg-12 col-md-12 col-sm-12">
                    <h1>Nueva Empresa</h1>
                    <EmpresaForm
                        onSubmit={this.guardarEmpresa}
                        print_state={print_state}
                        cambioFormatoFactura={cambioFormatoFactura}
                        formato_factura={formato_factura}
                        formato_factura_fel={formato_factura_fel}
                        datos_ejemplo={datos_ejemplo}
                        handleselectedFileCertificado={this.handleselectedFileCertificado}
                        handleselectedFileLlave={this.handleselectedFileLlave}
                        llave={llave}
                        certificado={certificado}
                        categorias={categorias}
                        categorias_seleccionados={categorias_seleccionados}
                        categorias_expanded={categorias_expanded}
                        asignarCategoria={asignarCategoria}
                        setCategoriasExpanded={setCategoriasExpanded}
                        cambioTipoFormatoFactura={cambioTipoFormatoFactura}
                        print={print}
                        />
                </div>
            </div>
        )
    }
}

export default EmpresaCrear
