import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import './accordion.css';
import './menu.css';
import { icons } from "icons";
import PropTypes from 'prop-types'


class Menu extends Component {
    static propTypes = {
        logOut: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);
    }

    render() {
        const url = window.location.href;
        const { logOut } = this.props;

        return (
            <div style={{ position: "relative" }}>
                <div className="v-menu v-menu-front">
                    <div style={{ textAlign: "center", marginBottom: "15px" }}><a href="#/" className="imagen-sidebar"><img className="img-responsive" src={require('../../../../assets/img/logo_small.png')} alt="Logo" /></a></div>
                    <Link className={`text-center sidebar-item menu-item ${url.includes("/home") ? "activo" : ""}`} to={"/home"}>
                        <img src={icons.home} alt="" />
                        <div>Inicio</div>
                    </Link>
                    <Link className={`text-center sidebar-item menu-item ${url.includes("/empresas") ? "activo" : ""}`} to={"/empresas"}>
                        <img src={icons.sucursales} alt="" />
                        <div>Empresas</div>
                    </Link>
                    <Link className={`text-center sidebar-item menu-item ${url.includes("/bienvenida") ? "activo" : ""}`} to={"/bienvenida"}>
                        <img src={icons.configuracion} alt="" />
                        <div>Bienvenida</div>
                    </Link>
                    {/* <Link className={`text-center sidebar-item menu-item ${url.includes("/categorias") ? "activo" : ""}`} to={"/categorias"}>
                        <em className="fa fa-list text-white"></em>
                        <div>Categorías</div>
                    </Link> */}
                    <a className="text-center sidebar-item menu-item" href="#" onClick={logOut}>
                        <img className="img-inactivo" src={icons.cancelar} alt="" />
                        <div>Salir</div>
                    </a>
                </div>
            </div>
        );
    }
}

export default Menu;

