import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SucursalForm from './SucursalEditarForm'
import LoadMask from "Utils/LoadMask/LoadMask";

class SucursalEditar extends Component {
    static propTypes = {
        editarSucursal: PropTypes.func.isRequired,
        loadEmpresaEdit: PropTypes.func.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
    }
    componentWillMount() {
        // leer desde el backend la empresa y la sucursal
        const { id, idSucursal } = this.props.match.params
        this.props.loadEmpresaEdit(id, idSucursal);
    }

    render() {
        // state
        const { editarSucursal, loader, empresa } = this.props;
        const { id } = this.props.match.params;
        // bind

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <LoadMask loading={loader} dark blur>
                        <SucursalForm onSubmit={editarSucursal} idEmpresa={id} empresa={empresa}/>
                    </LoadMask>
                </div>
            </div>
        )
    }
}

export default SucursalEditar
