import React from "react";
import { icons } from "icons";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { validate, validators, validatorFromFunction } from "validate-redux-form";
import { renderCheckBox } from "Utils/renderField/renderFieldMultiSelect";
import { Field, FieldArray, reduxForm, formValueSelector } from "redux-form";
import { renderField, renderSelectField, renderSearchSelect } from "Utils/renderField/renderField";


const Form = props => {
    const { handleSubmit, changeValue, reportes, despacho, configuracion, sucursales, changePermisos, rolValue, esGlobal, id_empresa } = props;

    //  Valores de checkbox de permisos
    const {
        dashboardValue, bodegaValue, punto_ventaValue,
        productoValue, gastoValue, bitacoraValue,
        reporte_balance_generalValue, reporte_apertura_cierreValue,
        reporte_bodegaValue, reporte_ventaValue, reporte_devolucionValue,
        reporte_cuenta_cobrarValue, reporte_gastoValue, reporte_productos_mas_vendidosValue,
        reporte_mejores_clientesValue, reporte_total_invertidoValue, reporte_ganancia_brutaValue,
        conf_tiendaValue, conf_usuarioValue,
        conf_sucursalValue, conf_clienteValue, conf_proveedorValue,
        conf_cajaValue, perfilValue, despacho_despachosValue, despacho_recepcionesValue,
        reporte_despachosValue
    } = props;

    const roles = [
        {
            id:"es_admin",
            value: "es_admin",
            label: "Administrador"
        },
        {
            id:"es_cajero",
            value: "es_cajero",
            label: "Cajero"
        },
        {
            id:"es_bodeguero",
            value: "es_bodeguero",
            label: "Bodeguero"
        },
        {
            id:"personalizado",
            value: "personalizado",
            label: "Personalizado"
        }
    ];

    const lectura = rolValue === "es_admin" || rolValue === "es_cajero" || rolValue === "es_bodeguero";

    return (
        <form onSubmit={handleSubmit} className="row mt">
            <div className="col-sm-12">
                <div className="form-group">
                    <div className="padding-15 p-sm-0 pt-sm-1">
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Datos del usuario</h3>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="first_name">Nombre Completo*</label>
                                <Field name="first_name" component={renderField} type="text" className="form-control"/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="usuario">Nombre de usuario para ingreso a sistema*</label>
                                <Field name="usuario" component={renderField} type="text" className="form-control"/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="rol">Asignar rol*</label>
                                <Field name="rol" component={renderSelectField} options={roles}
                                    className="form-control" item_vacio={true} onChange={e => {
                                        changePermisos(e.target.value, 'UsuarioEditar');
                                    }}/>
                            </div>
                            <div className="col-sm-12">
                                <h3>Sucursales</h3>
                            </div>
                            <div className="col-12 col-md-12 form-group">
                                <label>Indique el nivel de navegación del usuario</label>
                                <div className="d-flex">
                                    <div className="radio c-radio c-radio-nofont d-flex ml-3">
                                        <label className="negro font-weight-normal">
                                            <Field name="es_global" component="input" type="radio" value="true" />
                                            <span />
                                            Empresa completa <small>Capaz de filtrar por cualquier sucursal o ver la empresa como conjunto</small>
                                        </label>
                                    </div>
                                    <div className="radio c-radio c-radio-nofont d-flex ml-3">
                                        <label className="negro font-weight-normal">
                                            <Field name="es_global" component="input" type="radio" value="false" />
                                            <span />
                                            Sucursales especificadas <small>Capaz de ver información de las sucursales asignadas</small>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {(esGlobal === "false") &&
                            (<div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="sucursales">Indique a qué sucursales podrá acceder el usuario*</label>
                                <FieldArray name="sucursales" component={renderCheckBox} items={sucursales}/>
                            </div>)
                            }
                        </div>
                        <div className="row">
                            <div className="col-12 mt-2">
                                <h3>Permisos</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-4">
                                {
                                    (lectura) ? (
                                        <div className="w-100 p-3 font-weight-bold">
                                            <span className={`fa fa-check fa-lg mr-2  ${dashboardValue ? "naranja": "transparente"}`}/>
                                            Dashboard
                                        </div>

                                    ) :  (
                                        <div className="checkbox c-checkbox p-3">
                                            <label className="needsclick">
                                                <Field name="dashboard" component="input" type="checkbox"/>
                                                <span className="fa fa-check" />
                                                Dashboard
                                            </label>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                {
                                    (lectura) ? (
                                        <div className="w-100 p-3 font-weight-bold">
                                            <span className={`fa fa-check fa-lg mr-2  ${bodegaValue ? "naranja": "transparente"}`}/>
                                            Bodega
                                        </div>
                                    ) :  (
                                        <div className="checkbox c-checkbox p-3">
                                            <label className="needsclick">
                                                <Field name="bodega" component="input" type="checkbox" />
                                                <span className="fa fa-check" />
                                                Bodega
                                            </label>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                {
                                    (lectura) ? (
                                        <div className="w-100 p-3 font-weight-bold">
                                            <span className={`fa fa-check fa-lg mr-2  ${perfilValue ? "naranja": "transparente"}`}/>
                                            Perfil
                                        </div>
                                    ) :  (
                                        <div className="checkbox c-checkbox p-3">
                                            <label className="needsclick">
                                                <Field name="perfil" component="input" type="checkbox" />
                                                <span className="fa fa-check" />
                                                Perfil
                                            </label>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                {
                                    (lectura) ? (
                                        <div className="w-100 p-3 font-weight-bold">
                                            <span className={`fa fa-check fa-lg mr-2  ${punto_ventaValue ? "naranja": "transparente"}`}/>
                                            Punto de venta
                                        </div>
                                    ) :  (
                                        <div className="checkbox c-checkbox p-3">
                                            <label className="needsclick">
                                                <Field name="punto_venta" component="input" type="checkbox" />
                                                <span className="fa fa-check" />
                                                Punto de venta
                                            </label>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                {
                                    (lectura) ? (
                                        <div className="w-100 p-3 font-weight-bold">
                                            <span className={`fa fa-check fa-lg mr-2  ${productoValue ? "naranja": "transparente"}`}/>
                                            Productos
                                        </div>
                                    ) :  (
                                        <div className="checkbox c-checkbox p-3">
                                            <label className="needsclick">
                                                <Field name="producto" component="input" type="checkbox" />
                                                <span className="fa fa-check" />
                                                Productos
                                            </label>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                {
                                    (lectura) ? (
                                        <div className="w-100 p-3 font-weight-bold">
                                            <span className={`fa fa-check fa-lg mr-2  ${gastoValue ? "naranja": "transparente"}`}/>
                                            Reportar Gastos
                                        </div>
                                    ) :  (
                                        <div className="checkbox c-checkbox p-3">
                                            <label className="needsclick">
                                                <Field name="gasto" component="input" type="checkbox" />
                                                <span className="fa fa-check" />
                                                Reportar Gastos
                                            </label>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                {
                                    (lectura) ? (
                                        <div className="w-100 p-3 font-weight-bold">
                                            <span className={`fa fa-check fa-lg mr-2  ${bitacoraValue ? "naranja": "transparente"}`}/>
                                            Bitácora
                                        </div>
                                    ) :  (
                                        <div className="checkbox c-checkbox p-3">
                                            <label className="needsclick">
                                                <Field name="bitacora" component="input" type="checkbox" />
                                                <span className="fa fa-check" />
                                                Bitácora
                                            </label>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4">
                                    {
                                        (lectura) ? (
                                            <div className="w-100 p-3 font-weight-bold">
                                                <span className={`fa fa-check fa-lg mr-2  ${despacho ? "naranja" : "transparente"}`} />
                                                Despacho
                                        </div>
                                        ) : (
                                                <div className="checkbox c-checkbox p-3">
                                                    <label className="needsclick">
                                                        <input type="checkbox" onChange={() => changeValue('despacho', 'UsuarioEditar')} checked={despacho} />
                                                        <span className="fa fa-check" />
                                                        Despacho
                                                    </label>
                                                </div>
                                            )
                                    }
                                    <div className="row">
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${despacho_despachosValue ? "naranja" : "transparente"}`} />
                                                        Despachos
                                                    </div>
                                                ) : (
                                                        <div className="checkbox c-checkbox ml-3">
                                                            <label className="needsclick">
                                                                <Field name="despacho_despachos" component="input" type="checkbox" />
                                                                <span className="fa fa-check" />
                                                                Despachos
                                                        </label>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${despacho_recepcionesValue ? "naranja" : "transparente"}`} />
                                                        Recepciones
                                                    </div>
                                                ) : (
                                                        <div className="checkbox c-checkbox ml-3">
                                                            <label className="needsclick">
                                                                <Field name="despacho_recepciones" component="input" type="checkbox" />
                                                                <span className="fa fa-check" />
                                                                Recepciones
                                                        </label>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    {
                                        (lectura) ? (
                                            <div className="w-100 p-3 font-weight-bold">
                                                <span className={`fa fa-check fa-lg mr-2  ${reportes ? "naranja": "transparente"}`}/>
                                                Reportes
                                            </div>
                                        ) :  (
                                            <div className="checkbox c-checkbox p-3">
                                                <label className="needsclick">
                                                    <input type="checkbox" onChange={() => changeValue('reportes', 'UsuarioEditar')} checked={reportes} />
                                                    <span className="fa fa-check" />
                                                    Reportes
                                                </label>
                                            </div>
                                        )
                                    }
                                    <div className="row">
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${reporte_balance_generalValue ? "naranja": "transparente"}`}/>
                                                        Balance general
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="reporte_balance_general" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Balance general
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${reporte_apertura_cierreValue ? "naranja": "transparente"}`}/>
                                                        Apertura y cierre
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="reporte_apertura_cierre" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Apertura y cierre
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${reporte_bodegaValue ? "naranja": "transparente"}`}/>
                                                        Bodega
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="reporte_bodega" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Bodega
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${reporte_ventaValue ? "naranja": "transparente"}`}/>
                                                        Ventas
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="reporte_venta" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Ventas
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${reporte_devolucionValue ? "naranja": "transparente"}`}/>
                                                        Devoluciones
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="reporte_devolucion" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Devoluciones
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${reporte_cuenta_cobrarValue ? "naranja": "transparente"}`}/>
                                                        Cuentas por cobrar
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="reporte_cuenta_cobrar" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Cuentas por cobrar
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${reporte_gastoValue ? "naranja": "transparente"}`}/>
                                                        Gastos
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="reporte_gasto" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Gastos
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${reporte_productos_mas_vendidosValue ? "naranja": "transparente"}`}/>
                                                        Productos más vendidos
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="reporte_productos_mas_vendidos" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Productos más vendidos
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${reporte_mejores_clientesValue ? "naranja": "transparente"}`}/>
                                                        Mejores clientes
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="reporte_mejores_clientes" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Mejores clientes
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${reporte_total_invertidoValue ? "naranja": "transparente"}`}/>
                                                        Total invertido
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="reporte_total_invertido" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Total invertido
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${reporte_ganancia_brutaValue ? "naranja": "transparente"}`}/>
                                                        Ganancia bruta
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="reporte_ganancia_bruta" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Ganancia bruta
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${reporte_despachosValue ? "naranja": "transparente"}`}/>
                                                        Despachos
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="reporte_despachos" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Despachos
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    {
                                        (lectura) ? (
                                            <div className="w-100 p-3 font-weight-bold">
                                                <span className={`fa fa-check fa-lg mr-2  ${configuracion ? "naranja": "transparente"}`}/>
                                                Configuración
                                            </div>
                                        ) :  (
                                            <div className="checkbox c-checkbox p-3">
                                                <label className="needsclick">
                                                    <input type="checkbox" onChange={() => changeValue('configuracion', 'UsuarioEditar')} checked={configuracion} />
                                                    <span className="fa fa-check" />
                                                    Configuración
                                                </label>
                                            </div>
                                        )
                                    }
                                    <div className="row">
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${conf_tiendaValue ? "naranja": "transparente"}`}/>
                                                        Tienda
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="conf_tienda" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Tienda
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${conf_usuarioValue ? "naranja": "transparente"}`}/>
                                                        Usuarios
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="conf_usuario" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Usuarios
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${conf_sucursalValue ? "naranja": "transparente"}`}/>
                                                        Sucursales
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="conf_sucursal" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Sucursales
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${conf_clienteValue ? "naranja": "transparente"}`}/>
                                                        Clientes
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="conf_cliente" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Clientes
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${conf_proveedorValue ? "naranja": "transparente"}`}/>
                                                        Proveedores
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="conf_proveedor" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Proveedores
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-12">
                                            {
                                                (lectura) ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span className={`fa fa-check fa-lg mr-2  ${conf_cajaValue ? "naranja": "transparente"}`}/>
                                                        Cajas
                                                    </div>
                                                ) :  (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field name="conf_caja" component="input" type="checkbox" />
                                                            <span className="fa fa-check" />
                                                            Cajas
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <br/>
                        <div className="d-flex justify-content-center flex-row">
                            <Link className="btn btn-secondary m-1 align-self-center"  to={`/empresas/editar/${id_empresa}`}><img src={icons.cancelar} alt=""/>Cancelar</Link>
                            <button type="submit" className="btn btn-primary m-1 align-self-center"><img src={icons.save} alt=""/>Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

let UsuariosEditarForm = reduxForm({
    // a unique name for the form
    form: "UsuarioEditar",
    validate: data => {
        const sucursales = validatorFromFunction(() => {
            if (data.es_global) {
                return true;
            }
            let valid = false;
            data.sucursales.forEach((item) => {
                if (item[Object.keys(item)[0]])
                    valid = true;
            });
            return valid;
        });
        return validate(data, {
            username: validators.exists()('Campo requerido'),
            es_global: validators.exists()('Campo requerido'),
            first_name: validators.exists()('Campo requerido'),
            sucursales: sucursales()('Campo requerido'),
            rol: validators.exists()('Campo requerido')
        })
    }
})(Form);

//  Decorador para leer los valores del form
const selector = formValueSelector('UsuarioEditar');
UsuariosEditarForm = connect(
    state => {
        const rolValue = selector(state, "rol");

        //  Valores de checkbox de permisos
        const esGlobal = selector(state, "es_global");
        const dashboardValue = selector(state, "dashboard");
        const perfilValue = selector(state, "perfil");
        const bodegaValue = selector(state, "bodega");
        const punto_ventaValue = selector(state, "punto_venta");
        const productoValue = selector(state, "producto");
        const gastoValue = selector(state, "gasto");
        const bitacoraValue = selector(state, "bitacora");
        const reporte_balance_generalValue = selector(state, "reporte_balance_general");
        const reporte_apertura_cierreValue = selector(state, "reporte_apertura_cierre");
        const reporte_bodegaValue = selector(state, "reporte_bodega");
        const reporte_ventaValue = selector(state, "reporte_venta");
        const reporte_devolucionValue = selector(state, "reporte_devolucion");
        const reporte_cuenta_cobrarValue = selector(state, "reporte_cuenta_cobrar");
        const reporte_gastoValue = selector(state, "reporte_gasto");
        const reporte_productos_mas_vendidosValue = selector(state, "reporte_productos_mas_vendidos");
        const reporte_mejores_clientesValue = selector(state, "reporte_mejores_clientes");
        const reporte_total_invertidoValue = selector(state, "reporte_total_invertido");
        const reporte_ganancia_brutaValue = selector(state, "reporte_ganancia_bruta");
        const reporte_despachosValue = selector(state, "reporte_despachos");
        const conf_tiendaValue = selector(state, "conf_tienda");
        const conf_usuarioValue = selector(state, "conf_usuario");
        const conf_sucursalValue = selector(state, "conf_sucursal");
        const conf_clienteValue = selector(state, "conf_cliente");
        const conf_proveedorValue = selector(state, "conf_proveedor");
        const conf_cajaValue = selector(state, "conf_caja");
        const despacho_despachosValue = selector(state, "despacho_despachos");
        const despacho_recepcionesValue = selector(state, "despacho_recepciones");

        return {
            rolValue,
            esGlobal,
            dashboardValue,
            perfilValue,
            bodegaValue,
            punto_ventaValue,
            productoValue,
            gastoValue,
            bitacoraValue,
            reporte_balance_generalValue,
            reporte_apertura_cierreValue,
            reporte_bodegaValue,
            reporte_ventaValue,
            reporte_devolucionValue,
            reporte_cuenta_cobrarValue,
            reporte_gastoValue,
            reporte_productos_mas_vendidosValue,
            reporte_mejores_clientesValue,
            reporte_total_invertidoValue,
            reporte_ganancia_brutaValue,
            reporte_despachosValue,
            conf_tiendaValue,
            conf_usuarioValue,
            conf_sucursalValue,
            conf_clienteValue,
            conf_proveedorValue,
            conf_cajaValue,
            despacho_despachosValue,
            despacho_recepcionesValue,
            initialValues: {
                sucursales: []
            }
        }
    }
)(UsuariosEditarForm);

export default UsuariosEditarForm;
