import React from "react";
import { icons } from "icons";
import classNames from "classnames";
import { connect } from "react-redux";
import TreeCategoria from "../TreeCategoria";
import { PrintComponent } from "Utils/Print";
import { PrintContainer } from "Utils/Print";
import NumberFormat from "react-number-format";
// import { renderCurrency } from 'Utils/renderField/renderField';
import { Field, FieldArray, reduxForm, formValueSelector } from "redux-form";
import {
    RenderDateTime,
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";

// funcion para render de un campo,
// crea automaticamente las clases y elementos de validacion
// parametros establecidos por redux-form
// TODO: mover componente para que sea reutilizable
const renderField = ({ input, label, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                {...input}
                placeholder={label}
                type={type}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

const renderFieldSelect = (field) => {
    const invalid = field.meta.touched && field.meta.error;
    return (
        <div>
            <select
                {...field.input}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
            >
                <option value={undefined}></option>
                {field.listado.map((option) => (
                    <option value={option.id} key={option.id}>
                        {option.nombre}
                    </option>
                ))}
            </select>
            {invalid && (
                <div className="invalid-feedback">{field.meta.error}</div>
            )}
        </div>
    );
};

const renderCurrency = ({
    className,
    input,
    disabled,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <NumberFormat
                className={classNames(className ? className : "form-control", {
                    "is-invalid": invalid,
                })}
                decimalScale={2}
                fixedDecimalScale={true}
                value={input.value}
                thousandSeparator={true}
                prefix={"Q "}
                disabled={disabled}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

const renderFrases = ({ fields, meta: { touched, error, submitFailed } }) => {
    return (
        <div className="row">
            <div className="col-12">
                <button
                    type="button"
                    onClick={() => fields.push({})}
                    className="btn btn-secondary mb-3"
                >
                    <em className="fa fa-plus-circle ml-3"></em> Nueva frase
                </button>
                <br />
                {(touched || submitFailed) && error && (
                    <span className="invalid-feedback d-block fnt-14 mb-4">
                        {error}
                    </span>
                )}
            </div>
            <div className="col-12">
                <table className="table table-bordered table-sm">
                    <thead className="">
                        <tr className="bg-primary">
                            <th
                                className="text-white"
                                scope="col"
                                style={{ width: "20%" }}
                            >
                                Tipo
                            </th>
                            <th
                                className="text-white"
                                scope="col"
                                style={{ width: "20%" }}
                            >
                                Código ecenaro
                            </th>
                            <th className="text-white" scope="col">
                                Descripción
                            </th>
                            <th
                                className="text-white text-center"
                                scope="col"
                                style={{ width: "10%" }}
                            ></th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((frase, index) => (
                            <tr key={index}>
                                <td>
                                    <Field
                                        name={`${frase}.tipo`}
                                        type="text"
                                        component={renderField}
                                        label="Tipo"
                                    />
                                </td>
                                <td>
                                    <Field
                                        name={`${frase}.codigo`}
                                        type="text"
                                        component={renderField}
                                        label="Código ecenario"
                                    />
                                </td>
                                <td>
                                    <Field
                                        name={`${frase}.descripcion`}
                                        type="text"
                                        component={renderField}
                                        label="Descripcion"
                                    />
                                </td>
                                <th scope="row" className="text-center">
                                    <button
                                        className="btn btn-danger"
                                        type="button"
                                        title="Elminar frase"
                                        onClick={() => fields.remove(index)}
                                    >
                                        <em className="fa fa-trash"></em>
                                    </button>
                                </th>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

// VALIDACIONES
const validate = (values, props) => {
    const errors = {};

    if (!values.nombre) errors.nombre = "Campo requerido";
    if (!values.subdominio) errors.subdominio = "Campo requerido";

    if (values.factura_personalizada === true) {
        if (!values.item_por_factura)
            errors.item_por_factura = "Campo requerido";
    }
    if (values.propietario) {
        errors.propietario = {};
        if (!values.propietario.username)
            errors.propietario.username = "Campo requerido";
        if (!values.propietario.first_name)
            errors.propietario.first_name = "Campo requerido";
        if (!values.propietario.last_name)
            errors.propietario.last_name = "Campo requerido";
        if (!values.propietario.password)
            errors.propietario.password = "Campo requerido";
        if (!values.propietario.email)
            errors.propietario.email = "Campo requerido";
        else {
            if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values.propietario.email
                )
            )
                errors.propietario.email = "Correo electrónico invalido";
        }
    } else {
        errors.propietario = {};
        errors.propietario.username = "Campo requerido";
        errors.propietario.first_name = "Campo requerido";
        errors.propietario.last_name = "Campo requerido";
        errors.propietario.password = "Campo requerido";
        errors.propietario.email = "Campo requerido";
    }

    if (!values.pais) errors.pais = "Campo requerido";

    if (values.utiliza_fel === true) {
        if (!values.direccion) errors.direccion = "Campo requerido";
        if (!values.nit) errors.nit = "Campo requerido";
        if (!values.nombre_emisor) errors.nombre_emisor = "Campo requerido";
        if (!values.nombre_comercial)
            errors.nombre_comercial = "Campo requerido";
        if (values.correo_emisor) {
            if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values.correo_emisor
                )
            )
                errors.correo_emisor = "Correo electrónico invalido";
        }
        if (!values.tipo_factura || values.tipo_factura == "")
            errors.tipo_factura = "Campo requerido";
        if (!values.usuario_fel) errors.usuario_fel = "Campo requerido";
        if (!values.llave_fel) errors.llave_fel = "Campo requerido";
        if (!values.alias_fel) errors.alias_fel = "Campo requerido";
        if (!values.llave_fel_firma) errors.llave_fel_firma = "Campo requerido";

        if (!values.frases || !values.frases.length) {
            errors.frases = { _error: "Tiene que tener al menos una frase" };
        } else {
            const frasesArrayErrors = [];
            values.frases.forEach((member, memberIndex) => {
                const memberErrors = {};
                if (!member || !member.tipo) {
                    memberErrors.tipo = "Campo requerido";
                    frasesArrayErrors[memberIndex] = memberErrors;
                }
                if (!member || !member.codigo) {
                    memberErrors.codigo = "Campo requerido";
                    frasesArrayErrors[memberIndex] = memberErrors;
                }
            });
            if (frasesArrayErrors.length) {
                errors.frases = frasesArrayErrors;
            }
        }
    }

    return errors;
};

// componente de formulario, maqueta el form y los inputs
// Componentes Field son requeridos e implementados por redux-form
const Form = (props) => {
    //  state
    const {
        formato_factura,
        datos_ejemplo,
        facturaPersonalizadaValue,
        utilizaFELValue,
        conf_tienda_onlineValue,
        categorias,
        categorias_seleccionados,
        categorias_expanded,
        print_state,
    } = props;

    //bind
    const {
        handleSubmit,
        empresa,
        cambioFormatoFactura,
        asignarCategoria,
        setCategoriasExpanded,
        cambioTipoFormatoFactura,
        print,
    } = props;

    const options = {
        lineNumbers: true,
    };

    const listadoPaises = [
        {
            id: "GT",
            nombre: "Guatemala",
        },
        {
            id: "MX",
            nombre: "México",
        },
    ];
    const listadoTipoFactura = [
        {
            id: "FACT",
            nombre: "Factura",
        },
        {
            id: "FPEQ",
            nombre: "Factura pequeño contribuyente",
        },
    ];
    const listadoAfiliacionIVA = [
        {
            id: "GEN",
            nombre: "General",
        },
        {
            id: "PEQ",
            nombre: "Pequeño contribuyente",
        },
    ];

    const ubicacionesTotal = [
        {
            id: 10,
            nombre: "Por página",
        },
        {
            id: 30,
            nombre: "Última página",
        },
    ];

    return (
        <form onSubmit={handleSubmit} className="row mt2">
            <div className="col-12">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                            <h2>Datos de la empresa</h2>
                            <label htmlFor="nombre">Nombre *</label>
                            <Field
                                name="nombre"
                                component={renderField}
                                type="text"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="nombre_comercial">
                                Nombre comercial{" "}
                            </label>
                            <Field
                                name="nombre_comercial"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="subdominio">Subdominio *</label>
                            <Field
                                name="subdominio"
                                component={renderField}
                                type="text"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="direccion">Dirección</label>
                            <Field
                                name="direccion"
                                component={renderField}
                                type="text"
                            />
                        </div>
                        {/* <div className="form-group">
                            <label htmlFor="codigo_postal">Código postal</label>
                            <Field name="codigo_postal" component={renderField} type="text" className="form-control" />
                        </div> */}
                        <div className="form-group">
                            <label htmlFor="telefono">Teléfono</label>
                            <Field
                                name="telefono"
                                component={renderField}
                                type="text"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="pais">País *</label>
                            <Field
                                name="pais"
                                component="select"
                                className="form-control"
                            >
                                {/* <option value="">Select a color...</option> */}
                                {listadoPaises.map((paisOption) => (
                                    <option
                                        value={paisOption.id}
                                        key={paisOption.id}
                                    >
                                        {paisOption.nombre}
                                    </option>
                                ))}
                            </Field>
                        </div>
                        <div
                            className="row"
                            style={{
                                marginLeft: "-15px",
                                marginRight: "-15px",
                            }}
                        >
                            <div className="col-12 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label htmlFor="costo_inscripcion">
                                        Costo inscripción
                                    </label>
                                    <Field
                                        name="costo_inscripcion"
                                        component={renderCurrency}
                                        decimalScale={2}
                                        type="text"
                                        className="form-control text-right"
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-lg-3">
                                <div className="form-group">
                                    <label htmlFor="costo_mensual">
                                        Costo mensual
                                    </label>
                                    <Field
                                        name="costo_mensual"
                                        component={renderCurrency}
                                        decimalScale={2}
                                        type="text"
                                        className="form-control text-right"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <h2>Datos del propietario</h2>
                        <div className="form-group">
                            <label htmlFor="propietario[username]">
                                Usuario propietario *
                            </label>
                            <Field
                                name="propietario[username]"
                                component={renderField}
                                type="text"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="propietario[first_name]">
                                Nombres del propietario
                            </label>
                            <Field
                                name="propietario[first_name]"
                                component={renderField}
                                type="text"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="propietario[last_name]">
                                Apellidos del propietario
                            </label>
                            <Field
                                name="propietario[last_name]"
                                component={renderField}
                                type="text"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="propietario[password]">
                                Contraseña del propietario *
                            </label>
                            <Field
                                name="propietario[password]"
                                component={renderField}
                                type="text"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="propietario[email]">
                                Email del propietario
                            </label>
                            <Field
                                name="propietario[email]"
                                component={renderField}
                                type="email"
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="conf_tienda_online">
                                Habilitar tienda online &nbsp;
                            </label>
                            <Field
                                name="conf_tienda_online"
                                component="input"
                                type="checkbox"
                            />
                        </div>
                    </div>
                </div>

                {conf_tienda_onlineValue && (
                    <div className="col-12">
                        <div className="form-group ml-2">
                            <label htmlFor="categorias">
                                Seleccione la categoría
                            </label>
                        </div>
                        <TreeCategoria
                            categorias={categorias}
                            asignarCategoria={asignarCategoria}
                            setCategoriasExpanded={setCategoriasExpanded}
                            categorias_seleccionados={categorias_seleccionados}
                            categorias_expanded={categorias_expanded}
                        />
                    </div>
                )}

                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="utiliza_fel">
                                Utiliza FEL &nbsp;
                            </label>
                            <Field
                                name="utiliza_fel"
                                component="input"
                                type="checkbox"
                            />
                        </div>
                    </div>
                </div>
                {utilizaFELValue && (
                    <div className="w-100 mb">
                        <div className="col-sm-12">
                            <h2>Datos para FEL</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-lg-4 col-12">
                                <div className="form-group">
                                    <label htmlFor="nit">NIT *</label>
                                    <Field
                                        name="nit"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-12">
                                <div className="form-group">
                                    <label htmlFor="nombre_emisor">
                                        Nombre emisor (Razón social) *
                                    </label>
                                    <Field
                                        name="nombre_emisor"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-12">
                                <div className="form-group">
                                    <label htmlFor="correo_emisor">
                                        Correo emisor{" "}
                                    </label>
                                    <Field
                                        name="correo_emisor"
                                        component={renderField}
                                        type="email"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-12">
                                <div className="form-group">
                                    <label htmlFor="tipo_factura">
                                        Tipo de factura *
                                    </label>
                                    <Field
                                        name="tipo_factura"
                                        listado={listadoTipoFactura}
                                        component={renderFieldSelect}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-12">
                                <div className="form-group">
                                    <label htmlFor="usuario_fel">
                                        Usuario *
                                    </label>
                                    <Field
                                        name="usuario_fel"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-12">
                                <div className="form-group">
                                    <label htmlFor="llave_fel">Llave *</label>
                                    <Field
                                        name="llave_fel"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-12">
                                <div className="form-group">
                                    <label htmlFor="alias_fel">Alias *</label>
                                    <Field
                                        name="alias_fel"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-12">
                                <div className="form-group">
                                    <label htmlFor="llave_fel_firma">
                                        Llave firma *
                                    </label>
                                    <Field
                                        name="llave_fel_firma"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="frases">Frases</label>
                                    <FieldArray
                                        name="frases"
                                        component={renderFrases}
                                    />
                                </div>
                            </div>
                            {/* <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="codigo_establecimiento">Código del establecimiento *</label>
                                        <Field name="codigo_establecimiento" component={renderField} type="text" className="form-control" />
                                    </div>
                                </div> */}
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                            <label htmlFor="factura_personalizada">
                                Factura personalizada &nbsp;
                            </label>
                            <Field
                                name="factura_personalizada"
                                component="input"
                                type="checkbox"
                            />
                        </div>
                    </div>
                </div>

                {/* FORMATO FACTURA */}
                {print_state.flag && <PrintComponent />}
                {facturaPersonalizadaValue ? (
                    <div className="row col-12 pt-1 content-formato-factura">
                        <div className="col-sm-3">
                            <div className="form-group">
                                <label htmlFor="item_por_factura">
                                    Productos por factura
                                </label>
                                <Field
                                    name="item_por_factura"
                                    component={renderField}
                                    type="number"
                                />
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="form-group">
                                <label htmlFor="ubicacion_total">
                                    Ubicación del total
                                </label>
                                <Field
                                    name="ubicacion_total"
                                    component="select"
                                    className="form-control"
                                >
                                    {ubicacionesTotal.map((paisOption) => (
                                        <option
                                            value={paisOption.id}
                                            key={paisOption.id}
                                        >
                                            {paisOption.nombre}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                        </div>
                        <div className="col-12">
                            <h2>Formato factura</h2>
                            <div className="col-12">
                                <button
                                    type="button"
                                    className="btn btn-secondary mr-2"
                                    onClick={() =>
                                        cambioTipoFormatoFactura("normal")
                                    }
                                >
                                    Formato Normal
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary ml-2"
                                    onClick={() =>
                                        cambioTipoFormatoFactura("fel")
                                    }
                                    disabled={!utilizaFELValue}
                                >
                                    Formato FEL
                                </button>
                            </div>
                            <LiveProvider
                                className="d-flex flex-column"
                                code={formato_factura}
                                noInline={false}
                                scope={{
                                    ...datos_ejemplo,
                                    RenderNumber,
                                    RenderCurrency,
                                    RenderDateTime,
                                    icons,
                                }}
                            >
                                <LiveEditor
                                    className="live-editor"
                                    style={{ flex: 1 }}
                                    onChange={(val) => {
                                        cambioFormatoFactura(val);
                                    }}
                                />
                                <div className="mt1 mb1 content-row-end-center">
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            print("facturaVenta");
                                        }}
                                        className="btn btn-primary mt-lg"
                                        type={"button"}
                                    >
                                        <em className="fa fa-print" /> Imprimir
                                    </button>
                                </div>
                                <div style={{ flex: 1 }} className="d-table">
                                    <PrintContainer
                                        name="facturaVenta"
                                        className="impresion-venta d-table w-100"
                                    >
                                        <LivePreview className="d-table w-100" />
                                        <LivePreview className="salto-pagina d-table w-100" />
                                    </PrintContainer>
                                </div>
                            </LiveProvider>
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="col-sm-12 mt2">
                <button type="submit" className="btn btn-primary">
                    Crear empresa
                </button>
            </div>
        </form>
    );
};

// Redux-form, se debe usar un nombre unico para todos los forms
let EmpresaForm = reduxForm({
    form: "EmpresaCrear",
    validate,
    initialValues: {
        factura_personalizada: false,
        pais: "GT",
        tipo_factura: "",
        afiliacion_iva: "",
    },
})(Form);

//  Decorador para leer los valores del form
const selector = formValueSelector("EmpresaCrear");

EmpresaForm = connect((state) => {
    const facturaPersonalizadaValue = selector(state, "factura_personalizada");
    const utilizaFELValue = selector(state, "utiliza_fel");
    const conf_tienda_onlineValue = selector(state, "conf_tienda_online");
    return {
        facturaPersonalizadaValue,
        utilizaFELValue,
        conf_tienda_onlineValue,
    };
})(EmpresaForm);

export default EmpresaForm;
