import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UsuarioForm from './UsuariosEditarForm'
import { api } from "api";
import LoadMask from "Utils/LoadMask/LoadMask";
import ModalAyuda from "Utils/Ayuda";
import { icons } from "icons";

class UsuarioEditar extends Component {
    static propTypes = {
        editar: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props)
        this.state= {
            modalAyuda: false,
            itemsCrearEditar: [
                // {
                //     src: icons.usuario_crear_editar_p1,
                //     alt: "Paso 1"
                // },
                {
                    src: icons.usuario_crear_editar_p2,
                    alt: "Paso 1"
                },
                {
                    src: icons.usuario_crear_editar_p3,
                    alt: "Paso 2"
                },
                {
                    src: icons.usuario_crear_editar_p4,
                    alt: "Paso 3"
                },
                {
                    src: icons.usuario_crear_editar_p5,
                    alt: "Paso 4"
                }
            ],
        }
    }
    componentWillMount() {
        const {id, idUsuario } = this.props.match.params
        this.props.getInfoEmpresa(id);
        this.props.leer(idUsuario);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.sucursales_empresa.length > this.props.sucursales_empresa.length) {
            this.props.sincronizarSucursales();
        }
    }
    closeModal = () => {
        this.setState({modalAyuda: false});
    }
    openModal = () => {
        this.setState({modalAyuda: true});
    }

    render(){
        // state
        const { configuracion, reportes, sucursales_empresa, despacho, id_empresa } = this.props;
        const { modalAyuda, itemsCrearEditar } = this.state;
        // bind
        const {editar, loader, changePermisos} = this.props;

        return (
            <div className="row">
                {/* <div className="d-none d-md-block" style={{position:"absolute", right: "15px", top: "11px"}}>
                    <button className="btn btn-ayuda fnt-16 d-flex align-items-center justify-content-center" style={{width: "2rem", height: "2rem"}} onClick={() => this.openModal()}>
                        <img src={icons.ayuda} alt="ayuda" title="Ayuda" style={{margin: "0", height:"1.1rem"}}/>
                    </button>
                </div> */}
                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <LoadMask loading={loader} light blur radius>
                        <div className="form-group grid-container">
                            <div className="grid-titulo padding-15 d-flex align-items-center justify-content-between">
                                Edición de Usuario
                                {/* <button className="btn btn-ayuda fnt-16 d-flex align-items-center justify-content-center" style={{width: "2rem", height: "2rem"}} onClick={() => this.openModal()}>
                                    <img src={icons.ayuda} alt="ayuda" title="Ayuda" style={{margin: "0", height:"1.1rem"}}/>
                                </button> */}
                            </div>
                            <div className=" padding-15">
                                <UsuarioForm
                                    id_empresa={id_empresa}
                                    onSubmit={editar}
                                    configuracion={configuracion}
                                    reportes={reportes}
                                    despacho={despacho}
                                    changeValue={this.props.changeValue}
                                    sucursales={sucursales_empresa}
                                    changePermisos={changePermisos}/>
                            </div>
                        </div>

                    </LoadMask>
                </div>
                <ModalAyuda open={modalAyuda} closeModal={this.closeModal} openModal={this.openModal} items={itemsCrearEditar}/>
            </div>
        )
    }
}

export default UsuarioEditar
