import React, { Component } from 'react';
import { slide as Slide } from 'react-burger-menu';
import { Menu } from '../Menu';
import './navbar.css';
import './burger-sidebar.css';
import './dd-menu.css';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = { verMenu: false };
    }
    componentWillMount() {
        this.toggleMenu = this.toggleMenu.bind(this);
        this.logOut = this.logOut.bind(this);
    }

    toggleMenu() {
        return (e) => {
            e.preventDefault();
            this.setState({ verMenu: !this.state.verMenu });
        };
    }

    logOut(event) {
        this.props.logOut();
    }

    render() {

        return (
            <div>
                <header className="topnavbar-wrapper">
                    <nav className="navbar topnavbar">
                        <div className="nav-wrapper">
                            <ul className="nav navbar-nav">
                                <li>
                                    <a className="nav-logo" href="#/" style={{ padding: 0 }}><img className="img-responsive" src={require('../../../../assets/img/logo.png')} alt="Logo" /></a>
                                    <a className="sidebar-toggle"><em className="fa fa-navicon fa-2x" /></a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </header>
                <Slide id="bubble" pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
                    <Menu />
                </Slide>
            </div>
        );
    }
}
Navbar.propTypes = {
};

export default Navbar;
