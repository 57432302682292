import React, { Component } from 'react';
import  PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { activeFormatter } from "../../Utils/Acciones/Acciones";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "../../Utils/tableOptions";
import { Link } from 'react-router-dom';
import { icons } from "icons";
import { RenderDateTime } from "Utils/renderField/renderTableField";
import { RenderNumber } from "Utils/renderField/renderReadField";

const bloqueadaFormatter = (value) => {

            return value ? <img className="action-img" title="Ver" src={require("../../../../../assets/img/icons/down-s.png")} alt="Ver" /> :
            <img className="action-img" title="Ver" src={require("../../../../../assets/img/icons/up-s.png")} alt="Ver" />

}

class Detalle extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        eliminarEmpresa: PropTypes.func.isRequired,
        loader: PropTypes.bool
    }

    static defaultProps = {
        loader: false
    }

    constructor(props) {
        super(props)
    }

    componentDidMount(){
        this.props.listarEmpresas()
    }

    render(){
        // state
        const {data, loader, page, search} = this.props

        // bind
        const {eliminarEmpresa, listarEmpresas, eliminarDatos, buscar, sortChange} = this.props

        // local
        const options = {
            ...tableOptions,
            noDataText: loader ? 'Cargando...' : tableOptions.noDataText,
            onPageChange: listarEmpresas,
            onSortChange: sortChange,
            page,
        }

        const accionEliminarDatos = (id, datos) => {
            return (
                <span>
                    <img onClick={(e) => {
                        e.preventDefault();
                        eliminarDatos(id, datos)
                    }} className="action-img" src={icons.eliminar_datos} title="Eliminar productos y movimientos" alt="Eliminar productos y movimientos"/>
                </span>
            )
        }

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <div className="d-flex flex-row align-items-center justify-content-between">
                        <Link className="btn btn-primary mb mt1" to="/empresas/crear/">+ Agregar Empresa</Link>
                    </div>
                    <div className="grid-container">
                        <div className="grid-titulo">
                        <Toolbar titulo={"Empresas"} buscar={buscar} search={search}/>
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                                striped hover remote pagination>
                                <TableHeaderColumn isKey dataSort dataField='id'>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='creado' dataFormat={(cell) => <RenderDateTime fecha={cell}/>} dataSort >Fecha</TableHeaderColumn>
                                <TableHeaderColumn dataField='nombre' dataSort={true} >Nombre</TableHeaderColumn>
                                <TableHeaderColumn dataField='subdominio'>Subdominio</TableHeaderColumn>
                                <TableHeaderColumn dataField='direccion'>Dirección</TableHeaderColumn>
                                <TableHeaderColumn dataField='telefono'>Teléfono</TableHeaderColumn>
                                <TableHeaderColumn dataField='pais' dataFormat={(cell, row) =>
                                    <div>
                                        {
                                            cell=="GT" ? <span>Guatemala</span>:<span>México</span>
                                        }
                                    </div>
                                }>País</TableHeaderColumn>
                                <TableHeaderColumn dataField='costo_inscripcion' dataAlign="right" dataFormat={(cell, row) =>
                                    <div>
                                        Q. <RenderNumber value={cell.toFixed(2)} decimalScale={2} />
                                    </div>
                                }>Inscripción</TableHeaderColumn>
                                <TableHeaderColumn dataField='costo_mensual' dataAlign="right" dataFormat={(cell, row) =>
                                    <div>
                                        Q. <RenderNumber value={cell.toFixed(2)} decimalScale={2} />
                                    </div>
                                }>Mensualidad</TableHeaderColumn>
                                <TableHeaderColumn dataField='bloqueada' dataAlign='center' dataFormat={bloqueadaFormatter}>Activa</TableHeaderColumn>
                                <TableHeaderColumn dataField='id' dataAlign="center" width="20%" dataFormat={
                                    (cell, row) => {
                                        return activeFormatter({ editar: '/empresas/editar', eliminar: eliminarEmpresa, adicional: accionEliminarDatos, datos_adicional: row })(cell, row)
                                    }
                                }>Acciones</TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}

export default Detalle
