import React, { Component } from "react";
import PropTypes from "prop-types";
import UsuariosCrearForm from "./UsuariosCrearForm";
import LoadMask from "Utils/LoadMask/LoadMask";
import { api } from "api";
import ModalAyuda from "Utils/Ayuda";
import { icons } from "icons";


class UsuariosCrear extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        crear: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state= {
            modalAyuda: false,
            itemsCrearEditar: [
                // {
                //     src: icons.usuario_crear_editar_p1,
                //     alt: "Paso 1"
                // },
                {
                    src: icons.usuario_crear_editar_p2,
                    alt: "Paso 1"
                },
                {
                    src: icons.usuario_crear_editar_p3,
                    alt: "Paso 2"
                },
                {
                    src: icons.usuario_crear_editar_p4,
                    alt: "Paso 3"
                },
                {
                    src: icons.usuario_crear_editar_p5,
                    alt: "Paso 4"
                }
            ],
        }
    }

    componentWillMount() {
        const id = this.props.match.params.id
        this.props.getInfoEmpresa(id);
    }
    componentDidMount() {
        // refrescar form desde el backend
    }
    closeModal = () => {
        this.setState({modalAyuda: false});
    }
    openModal = () => {
        this.setState({modalAyuda: true});
    }

    render() {
        // state
        const { loader, configuracion, reportes, sucursales_empresa, despacho, id_empresa } = this.props;
        const { modalAyuda, itemsCrearEditar } = this.state;

        // bind
        const { crear, changePermisos, changeValue} = this.props;

        // const getSucursales = (search) => {
        //     return api.get("sucursales", {search}).catch(() => {}).then((data) => {
        //         return {options: data.results}
        //     })
        // };


        return (
            <div className="row">
                <div className="col-sm-12 mt-3">
                    <LoadMask loading={loader} light blur radius>
                        <div className="form-group grid-container">
                            <div className="grid-titulo padding-15 d-flex align-items-center justify-content-between">
                                Nuevo Usuario
                                {/* <button className="btn btn-ayuda fnt-16 d-flex align-items-center justify-content-center" style={{width: "2rem", height: "2rem"}} onClick={() => this.openModal()}>
                                    <img src={icons.ayuda} alt="ayuda" title="Ayuda" style={{margin: "0", height:"1.1rem"}}/>
                                </button> */}
                            </div>
                            <div className="">
                                <UsuariosCrearForm
                                    id_empresa={id_empresa}
                                    onSubmit={crear}
                                    configuracion={configuracion}
                                    reportes={reportes}
                                    despacho={despacho}
                                    changeValue={changeValue}
                                    sucursales={sucursales_empresa}
                                    changePermisos={changePermisos}
                                />
                            </div>
                        </div>
                    </LoadMask>
                </div>
                <ModalAyuda open={modalAyuda} closeModal={this.closeModal} openModal={this.openModal} items={itemsCrearEditar}/>
            </div>
        );
    }
}

export default UsuariosCrear;
