import { handleActions } from "redux-actions";
import { api } from "api";
import { push, replace } from "react-router-redux";
import _ from "lodash";
import Swal from "sweetalert2";
import { initialize as initializeForm } from "redux-form";

// ------------------------------------
// Constants
// ------------------------------------
const PAGE = "EMPRESA_PAGE";
const DATA = "EMPRESA_DATA";
const SORT = "EMPRESA_SORT";
const LOADER = "EMPRESA_LOADER";
export const BUSCADOR = "EMPRESA_BUSCADOR";
export const CATEGORIAS = "EMPRESA_CATEGORIAS";
const CARGAR_EDITDATA = "EMPRESA_CARGAR_EDITDATA";
const SET_FORMATO_FACTURA = "EMPRESA_SET_FORMATO_FACTURA";
export const CATEGORIAS_EXPANDED = "EMPRESA_CATEGORIAS_EXPANDED";
export const CATEGORIAS_SELECCIONADOS = "EMPRESA_CATEGORIAS_SELECCIONADOS";
const DATOS_EJEMPLO = "EMPRESA_DATOS_EJEMPLO";

const endpointCategorias = "categoria_empresa";

// ------------------------------------
// Pure Actions
// ------------------------------------

export const isLoading = (loading = true) => ({
    type: LOADER,
    loader: loading,
});

export const cargarEditData = (data) => ({
    type: CARGAR_EDITDATA,
    data,
});

export const setDatosEjemplo = (datos_ejemplo) => ({
    type: DATOS_EJEMPLO,
    datos_ejemplo,
});
// ------------------------------------
// Actions
// ------------------------------------

export const initCreacionEmpresa = () => (dispatch, getStore) => {
    const store = getStore();
    const formato_factura = store.usuario.formato_factura;

    dispatch(setFormatoFactura(formato_factura));
    dispatch(getCategorias());
};

export const crearEmpresa = (archivos) => (dispatch, getStore) => {
    const store = getStore();
    const nuevaEmpresa = store.form.EmpresaCrear;
    const { formato_factura, categorias_seleccionados } = store.empresas;
    if (nuevaEmpresa && nuevaEmpresa.values) {
        let datos_empresa = {
            ...nuevaEmpresa.values,
            formato_factura,
            nit: nuevaEmpresa.values.nit ? nuevaEmpresa.values.nit : null,
            correo_emisor: nuevaEmpresa.values.correo_emisor
                ? nuevaEmpresa.values.correo_emisor
                : null,
        };
        //  Se pasa a string las frases
        datos_empresa.frases = JSON.stringify(datos_empresa.frases);

        if (datos_empresa.conf_tienda_online) {
            datos_empresa.categorias = categorias_seleccionados;
        } else {
            datos_empresa.categorias = [];
        }

        api.post("empresas", datos_empresa)
            .then((data) => {
                if (data) {
                    Swal(
                        "Éxito",
                        "Datos almacenados correctamente",
                        "success"
                    ).then(() => {
                        // lanzar un replace, para poder regresar directo
                        // al listado de empresas si fuera necesario,
                        // sin regresar al formulario de agregar
                        dispatch(replace("/empresas/editar/" + data.id));
                    });
                }
            })
            .catch((data) => {
                console.log("error", data);
                let mensaje =
                    (!_.isEmpty(data) && data.detail) ||
                    "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
                Swal("ERROR", mensaje, "error");
            })
            .finally(() => {
                dispatch({ type: LOADER, loader: false });
            });
    }
};

export const editarEmpresa = (archivos) => (dispatch, getStore) => {
    const store = getStore();

    // los datos estan en redux-form EmpresaEditar
    let dataEditada = _.cloneDeep(store.form.EmpresaEditar.values);

    // formato de la factura
    const { formato_factura, categorias_seleccionados } = store.empresas;

    dataEditada.formato_factura = formato_factura;

    const id = dataEditada.id;
    //  Se pasa a string las frases
    dataEditada.frases = JSON.stringify(dataEditada.frases);

    if (dataEditada.conf_tienda_online) {
        dataEditada.categorias = categorias_seleccionados;
    } else {
        dataEditada.categorias = [];
    }

    api.put(`empresas/${id}/`, dataEditada)
        .then((data) => {
            if (data) {
                Swal(
                    "Éxito",
                    "Datos almacenados correctamente",
                    "success"
                ).then(() => {
                    dispatch(push("/empresas/"));
                });
            }
        })
        .catch((data) => {
            console.log("error", data);
            let mensaje =
                (!_.isEmpty(data) && data.detail) ||
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
            Swal("ERROR", mensaje, "error");
        })
        .finally(() => {
            dispatch({ type: LOADER, loader: false });
        });
};

export const listarEmpresas = (page = 1) => (dispatch, getStore) => {
    dispatch(isLoading(true));

    // params
    const store = getStore();
    const { search, ordering } = store.empresas;

    // api get
    api.get("empresas", { page, ordering, search })
        .catch((error) => {
            dispatch(isLoading(false));
        })
        .then((data) => {
            if (data) {
                dispatch(changePage(page));
                dispatch({ type: DATA, data });
            }
            dispatch(isLoading(false));
        });
};

// Carga la empresa desde el backend para
// el formulario de edición
export const loadEmpresaEdit = (id, idSucursal) => (dispatch, getStore) => {
    const store = getStore();
    const { datos_ejemplo } = store.empresas;
    const form_factura_default = store.usuario.formato_factura;

    dispatch(isLoading(true));

    // obtener el item desde el backend
    api.get(`empresas/${id}`)
        .catch((error) => {
            console.log("error en get empresa", error);
        })
        .then((data) => {
            if (data) {
                // se despacha manualmente la inicializacion del formulario
                // para evitar formularios vacios o que no se cargan bien al inicio
                let data_response = _.cloneDeep(data);

                // Se obtiene el formato de la factura de la empresa
                delete data_response.formato_factura;
                delete data_response.sucursales;
                delete data_response.unidades_de_medida;
                delete data_response.conf_caja_forzar_cierre;
                delete data_response.conf_venta_credito_marcar_entregado;
                delete data_response.conf_venta_imprimir_recibo;
                delete data_response.conf_venta_pedir_cliente;
                delete data_response.conf_venta_permitir_descuentos_items;

                const modelo_formato_factura = data.formato_factura.length
                    ? data.formato_factura[0]
                    : null;

                // Se setea la cantidad de productos por factura
                if (modelo_formato_factura) {
                    data_response.item_por_factura =
                        modelo_formato_factura.item_por_factura;
                    data_response.ubicacion_total =
                        modelo_formato_factura.ubicacion_total;
                }
                // Se asigna el que se encontro o se utiliza el default
                const formato_factura = modelo_formato_factura
                    ? modelo_formato_factura.formato
                    : form_factura_default;

                dispatch(setFormatoFactura(formato_factura));
                if (idSucursal) {
                    // tambien cargamos el form de sucursal
                    const sucursal = _.find(data["sucursales"], {
                        id: parseInt(idSucursal),
                    });
                    dispatch(initializeForm("SucursalEditar", sucursal));
                }
                // Se cargan todos los usuarios de la empresa
                let editData = _.cloneDeep(data);
                api.get("x_usuarios/lista_empresa", { empresa_id: id })
                    .catch((error) => {
                        console.log(
                            "error en get de usuarios de la empresa",
                            error
                        );
                    })
                    .then((dataUsr) => {
                        if (dataUsr) {
                            editData.usuarios = dataUsr;
                        }
                        // cargar empresa que se está editando, para poder listar
                        // las sucursales en el grid
                        dispatch(cargarEditData(editData));
                        data_response.frases = JSON.parse(data_response.frases);
                        dispatch(
                            initializeForm("EmpresaEditar", data_response)
                        );
                    });

                if (data_response.conf_tienda_online) {
                    let categorias = [];
                    let expanded = [];
                    //  Se recorren las categorías para obtener los ids
                    //  para poder renderizarlos en el componente (arbol de categorias)
                    data_response.categorias.forEach((categoria) => {
                        categorias.push(categoria.id.toString());

                        //  Se recorren los antecesores (padres) de la categoría
                        //  para poder mostrar las categorías ya expandidas
                        categoria.ancestors.forEach((id_ancestor) => {
                            const find = _.find(
                                expanded,
                                function (id_expanded) {
                                    return (
                                        id_expanded == id_ancestor.toString()
                                    );
                                }
                            );
                            //  Se valida que sea unico en el array de ids de antecesores
                            if (!find) {
                                expanded.push(id_ancestor.toString());
                            }
                        });
                    });
                    dispatch(setCategoriasSeleccionados(categorias));
                    dispatch(setCategoriasExpanded(expanded));
                }

                if (data_response.logo) {
                    let new_datos_ejemplo = _.cloneDeep(datos_ejemplo);
                    new_datos_ejemplo.sucursal.datos_empresa.logo_ticket =
                        data_response.logo_ticket;
                    dispatch(setDatosEjemplo(new_datos_ejemplo));
                }
            }
        })
        .finally(() => {
            dispatch(isLoading(false));
            dispatch(getCategorias());
        });
};

export const eliminarUsuario = (id) => (dispatch, getStore) => {
    const store = getStore();
    const idEmpresa = store.empresas.editando.id;
    dispatch(isLoading(true));
    api.eliminar(`x_usuarios/${id}`)
        .then((data) => {
            if (data) {
                // ToastStore.success("Registro eliminado.");
                Swal("Exito", "Usuario eliminado exitosamente.", "success");
                dispatch(loadEmpresaEdit(idEmpresa));
            }
        })
        .catch((error) => {
            Swal(
                "ERROR",
                error.detail ||
                    "Ha ocurrido un error, espere un momento y vuelva a intentar.",
                "error"
            );
        })
        .finally(() => {
            dispatch(isLoading(false));
        });
};

// llamar esta funcion luego de confirmar con swal
export const eliminarEmpresa = (id) => (dispatch) => {
    dispatch(isLoading(true));
    api.eliminar(`empresas/${id}`)
        .catch((error) => {
            console.log("error al eliminar empresa", error);
            let mensaje =
                (!_.isEmpty(error) && error.detail) ||
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
            Swal("ERROR", mensaje, "error");
        })
        .then((data) => {
            console.log("eliminado");
            // recargar listado de empresas
            dispatch(listarEmpresas());
            Swal("Exito", "Empresa eliminada exitosamente.", "success");
        })
        .finally(() => {
            dispatch(isLoading(false));
        });
};

// llamar esta funcion luego de confirmar con swal
export const eliminarDatos = (id, datos) => (dispatch) => {
    dispatch(isLoading(true));
    Swal({
        title: `¿Eliminar datos de la empresa ${datos.nombre}?`,
        text: "¡No podrá revertir esta acción!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, eliminar!",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            api.put(`empresas/${id}/aliminar_datos`)
                .catch((error) => {
                    console.log(
                        "error al eliminar los dato de la empresa",
                        error
                    );
                    Swal(
                        "Error",
                        "No se ha podido eliminar los datos de la empresa, intenta más tarde",
                        "error"
                    );
                })
                .then((data) => {
                    console.log("eliminado");
                    if (data) {
                        Swal(
                            "Exito",
                            "Se han eliminar los datos de la empresa",
                            "success"
                        );
                    }
                })
                .finally(() => {
                    dispatch(isLoading(false));
                });
        } else {
            dispatch(isLoading(false));
        }
    });
};

// edicion de sucursales de empresa
export const editarSucursal = () => (dispatch, getStore) => {
    const store = getStore();
    // los datos estan en redux-form EmpresaEditar
    const dataEditada = store.form.SucursalEditar.values;

    console.log("Put de sucursal", dataEditada);

    const id = dataEditada.id;
    const empresaId = dataEditada.empresa;

    api.put(`sucursales/${id}`, dataEditada)
        .then((data) => {
            if (data) {
                Swal(
                    "Éxito",
                    "Datos almacenados correctamente",
                    "success"
                ).then(() => {
                    dispatch(push("/empresas/editar/" + empresaId));
                });
            }
        })
        .catch((data) => {
            console.log("error", data);
            let mensaje =
                (!_.isEmpty(data) && data.detail) ||
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
            Swal("ERROR", mensaje, "error");
        })
        .finally(() => {
            dispatch({ type: LOADER, loader: false });
        });
};

// creacion de sucursales de empresa
export const crearSucursal = (idEmpresa) => (dispatch, getStore) => {
    const store = getStore();
    // los datos estan en redux-form EmpresaEditar
    const dataEditada = store.form.SucursalCrear.values;

    // asingar la empresa
    dataEditada["empresa_id"] = parseInt(idEmpresa);
    console.log("Post de sucursal", dataEditada);

    api.post("sucursales", dataEditada)
        .then((data) => {
            if (data) {
                Swal(
                    "Éxito",
                    "Datos almacenados correctamente",
                    "success"
                ).then(() => {
                    dispatch(push("/empresas/editar/" + idEmpresa));
                });
            }
        })
        .catch((data) => {
            let mensaje =
                (!_.isEmpty(data) && data.detail) ||
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
            Swal("ERROR", mensaje, "error");
        })
        .finally(() => {
            dispatch({ type: LOADER, loader: false });
        });
};

export const eliminarSucursal = (id) => (dispatch, getStore) => {
    const store = getStore();

    // los datos estan en redux-form EmpresaEditar
    const empresaId = store.form.EmpresaEditar.values.id;

    api.eliminar("sucursales/" + id)
        .then(() => {
            dispatch(loadEmpresaEdit(empresaId));
            Swal("Éxito", "Sucursal eliminada", "success");
        })
        .catch((data) => {
            let mensaje =
                (!_.isEmpty(data) && data.detail) ||
                "Ha ocurrido un error, por favor vuelva a intentar.";
            Swal("ERROR", mensaje, "error");
        })
        .finally(() => {
            dispatch({ type: LOADER, loader: false });
        });
};

export const cambioFormatoFactura = (formato_factura) => (dispatch) => {
    dispatch(setFormatoFactura(formato_factura));
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listarEmpresas());
};

//  CATEGORIAS
const getChilds = (node) => (dispatch) => {
    if (node.childs.length) {
        let tree = [];
        node.childs.forEach((sub_categoria) => {
            let child = {
                value: sub_categoria.id,
                label: sub_categoria.nombre,
            };
            if (sub_categoria.childs.length) {
                const result = dispatch(getChilds(sub_categoria));
                if (result) {
                    child.children = result;
                    tree.push(child);
                }
            } else {
                tree.push(child);
            }
        });
        return tree;
    } else {
        return false;
    }
};
const formatCategorias = (data) => (dispatch) => {
    let tree = [];
    data.forEach((categoria) => {
        let child = {
            value: categoria.id,
            label: categoria.nombre,
        };
        if (categoria.childs.length) {
            const result = dispatch(getChilds(categoria));
            if (result) {
                child.children = result;
                tree.push(child);
            }
        } else {
            tree.push(child);
        }
    });
    return tree;
};

export const getCategorias = () => (dispatch, getStore) => {
    dispatch(isLoading(true));
    api.get(`${endpointCategorias}/tree`)
        .catch((err) => {
            console.log("ERROR: ", err);
            ToastStore.error("No fue posible obtener el listado de categorías");
        })
        .then((resp) => {
            console.log(resp);
            if (resp) {
                const tree = dispatch(formatCategorias(resp.data));
                dispatch(setCategorias(tree));
            }
        })
        .finally(() => {
            dispatch(isLoading(false));
        });
};

export const asignarCategoria = (seleccionados) => (dispatch) => {
    dispatch(setCategoriasSeleccionados(seleccionados));
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.empresas.page;
    dispatch(listarEmpresas(page));
};

export const cambioTipoFormatoFactura = (tipo) => (dispatch, getStore) => {
    const store = getStore();
    const { formato_factura, formato_factura_fel } = store.usuario;
    Swal({
        title: `Cambio factura personalizada ${
            tipo == "fel" ? "con formato FEL" : ""
        }`,
        type: "info",
        text: "¿Quieres cambiar el formato de la factura?",
        showCancelButton: true,
        confirmButtonText: "Si, cambiar",
        cancelButtonText: "No, mantener",
    }).then((val) => {
        console.log("VAL: ", val);
        if (val.value) {
            dispatch(
                setFormatoFactura(
                    tipo == "fel" ? formato_factura_fel : formato_factura
                )
            );
        }
    });
};

export const changePage = (page) => ({
    type: PAGE,
    page,
});

export const setFormatoFactura = (formato_factura) => ({
    type: SET_FORMATO_FACTURA,
    formato_factura,
});

const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});

const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

export const setCategorias = (categorias) => ({
    type: CATEGORIAS,
    categorias,
});
export const setCategoriasSeleccionados = (categorias_seleccionados) => ({
    type: CATEGORIAS_SELECCIONADOS,
    categorias_seleccionados,
});
export const setCategoriasExpanded = (categorias_expanded) => ({
    type: CATEGORIAS_EXPANDED,
    categorias_expanded,
});

export const actions = {
    initCreacionEmpresa,
    crearEmpresa,
    listarEmpresas,
    loadEmpresaEdit,
    editarEmpresa,
    eliminarEmpresa,
    editarSucursal,
    crearSucursal,
    eliminarSucursal,
    cambioFormatoFactura,
    eliminarDatos,
    eliminarUsuario,
    buscar,
    sortChange,
    getCategorias,
    asignarCategoria,
    setCategoriasExpanded,
    cambioTipoFormatoFactura,
};

// ------------------------------------
// PureActions
// ------------------------------------

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [CARGAR_EDITDATA]: (state, { data }) => {
        return { ...state, editando: data };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SET_FORMATO_FACTURA]: (state, { formato_factura }) => {
        return {
            ...state,
            formato_factura,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [CATEGORIAS]: (state, { categorias }) => {
        return {
            ...state,
            categorias,
        };
    },
    [CATEGORIAS_SELECCIONADOS]: (state, { categorias_seleccionados }) => {
        return {
            ...state,
            categorias_seleccionados,
        };
    },
    [CATEGORIAS_EXPANDED]: (state, { categorias_expanded }) => {
        return {
            ...state,
            categorias_expanded,
        };
    },
    [DATOS_EJEMPLO]: (state, { datos_ejemplo }) => {
        return {
            ...state,
            datos_ejemplo,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    loader: false,
    editando: {
        sucursales: [],
        usuarios: [],
    },
    data: {
        count: 0,
        results: [],
    },
    page: 1,
    search: "",
    ordering: "",
    formato_factura: "",
    datos_ejemplo: {
        fecha: Date(),
        nombre: "Oscar Perez Mendez",
        nit: "1234567-8",
        direccion: "Ciudad Quetzaltenango",
        no_pagina: 1,
        codigo_venta: "SRC1-100",
        referencia: "Lacteos Xelac",
        detalle: [
            {
                id: 1,
                codigo_barras: "car123a",
                nombre: "RM EMPAQUE IAINDERA DE VALVULA JUEGO",
                preentacion: "Unidad",
                cantidad: 10,
                precio_unitario: 89.89,
                precio_original: 89.89,
                desc_porcentaje: 10,
                desc_monto: 8.9,
                es_vale: false,
                subtotal: 1000,
                sub_total: 1000,
                fraccion: {
                    id: 1,
                    producto: 1,
                    parent: 2,
                    unidad_de_medida: 1,
                    minimo_existencias: 2,
                    nombre: "Unidad",
                    capacidad_maxima: 2,
                    codigo_barras: "car123a",
                    precio: 100,
                    precio2: 100,
                    precio3: 100,
                    precio4: 100,
                    precio5: 100,
                    vendible: true,
                    fecha_ultima_compra: "2018-05-19",
                    costo: 25,
                    activo: true,
                    creado: "2018-05-19",
                    modificado: "2018-05-19",
                    eliminable: true,
                },
                producto: {
                    empresa: 1,
                    categoria: 1,
                    nombre: "Aspirina",
                    marca: "Bayer",
                    descripcion: "test",
                    inventariable: true,
                    vendible: true,
                    imagen: "",
                    img_cropped: "",
                    tiene_fracciones: false,
                    activo: false,
                    creado: "2018-05-19",
                    modificado: "2018-05-19",
                },
            },
            {
                id: 2,
                codigo_barras: "prod123a",
                nombre: "Diclofenaco",
                preentacion: "Caja",
                cantidad: 20,
                precio_unitario: 50,
                desc_porcentaje: 0,
                desc_monto: 0,
                es_vale: false,
                subtotal: 1000,
                sub_total: 1000,
                fraccion: {
                    id: 2,
                    producto: 1,
                    parent: 2,
                    unidad_de_medida: 1,
                    minimo_existencias: 2,
                    nombre: "Caja",
                    capacidad_maxima: 2,
                    codigo_barras: "prod123a",
                    precio: 50,
                    precio2: 50,
                    precio3: 50,
                    precio4: 50,
                    precio5: 50,
                    vendible: true,
                    fecha_ultima_compra: "2018-05-19",
                    costo: 25,
                    activo: true,
                    creado: "2018-05-19",
                    modificado: "2018-05-19",
                    eliminable: true,
                },
                producto: {
                    empresa: 1,
                    categoria: 1,
                    nombre: "Diclofenaco",
                    marca: "Bayer",
                    descripcion: "test",
                    inventariable: true,
                    vendible: true,
                    imagen: "",
                    img_cropped: "",
                    tiene_fracciones: false,
                    activo: false,
                    creado: "2018-05-19",
                    modificado: "2018-05-19",
                },
            },
            {
                id: 3,
                codigo_barras: "alk123",
                nombre: "Alka Seltzer",
                preentacion: "Fardo",
                cantidad: 5,
                precio_unitario: 95.5,
                desc_porcentaje: 0,
                desc_monto: 0,
                es_vale: false,
                subtotal: 477.5,
                sub_total: 477.5,
                fraccion: {
                    id: 3,
                    producto: 1,
                    parent: 2,
                    unidad_de_medida: 1,
                    minimo_existencias: 2,
                    nombre: "Fardo",
                    capacidad_maxima: 2,
                    codigo_barras: "alk123",
                    precio: 95.5,
                    precio2: 95.5,
                    precio3: 95.5,
                    precio4: 95.5,
                    precio5: 95.5,
                    vendible: true,
                    fecha_ultima_compra: "2018-05-19",
                    costo: 25,
                    activo: true,
                    creado: "2018-05-19",
                    modificado: "2018-05-19",
                    eliminable: true,
                },
                producto: {
                    empresa: 1,
                    categoria: 1,
                    nombre: "Alka Seltzer",
                    marca: "Bayer",
                    descripcion: "test",
                    inventariable: true,
                    vendible: true,
                    imagen: "",
                    img_cropped: "",
                    tiene_fracciones: false,
                    activo: false,
                    creado: "2018-05-19",
                    modificado: "2018-05-19",
                },
            },
        ],
        total: 2477.5,
        tipo_movimiento: "Venta",
        sucursal: {
            empresa: 2,
            nombre: "Sucursal test",
            direccion: "Quetzaltenango",
            telefono: "1234-5678",
            prefijo: "EMP",
            conteo: "2",
            nombre_comercial: "Sucursal Test",
            departamento: "Quetzaltenango",
            municipio: "Quetzaltenango",
            datos_empresa: {
                id: 1,
                nombre: "Empresa 1",
                direccion: "Quetzaltenango",
                telefono: "12345678",
                nombre_comercial: "Sucursal Test",
                nit: "7654321-8",
                logo: "static/img/logo.png",
                correo_tienda: "test@test.com",
                telefono_tienda: "12345678",
                nombre_emisor: "Sucursal Test S.A.",
                logo: null,
            },
        },
        usuario: "Usuario test",
        tipo_pago: 100,
        total_cobrar_pagar: 2477.5,
        monto_efectivo: 2000,
        monto_tarjeta: 477.5,
        monto_cheque: 0,
        monto_deposito: 0,
        monto_nota_credito: 0,
        monto_retencion_iva: 0,
        monto_exencion_iva: 0,
        pago_recibido: 2477.5,
        vuelto_efectivo: 0,
        logo: "static/img/logo.png",
        datos_fel: {
            serie: "**PRUEBAS**",
            numero: "196756897",
            uuid: "9617580F-0BBA-45A1-8F46-35E1FD68D372",
            respuesta_facturacion: "",
            estado_factura: 20,
            fecha_emision: "2020-06-23T16:04:46-06:00",
            fechaCertificacion: "2020-06-23T10:04:51-06:00",
            frase_fel: "Sujeto a pagos trimestrales ISR",
        },
        datos_certificador: {
            nit: "12521337",
            nombre: "INFILE, S.A.",
        },
        total_letras:
            "DOS MIL CUATROCIENTOS SETENTA Y SIETE QUETZALES CON CINCUENTA CENTAVOS",
    },
    categorias: [],
    categorias_seleccionados: [],
    categorias_expanded: [],
};

export default handleActions(reducers, initialState);
